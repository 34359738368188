header {
    height: 100vh;
    /* padding-top: 7rem; */
    overflow: hidden;
}

.header__container {
    text-align: center; 
    height: 100%;
    position: relative;
    overflow: hidden; 
     justify-content: center; 
}

/* CTA */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* Social Links */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* Developer Image */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 28rem;  
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 2.9rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 4rem 1.5rem 0 0;
    transition: all 0.5s ease-in-out;
    display: flex;
}

.me:hover img {
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.me img {
    object-fit: cover; 
    width: 100%;
    margin-top: -80px;
    transition: all 0.5s ease-in-out;
}

/* Scroll Down */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1rem;
}

/* Media Queries */

/* Medium Devices */
@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }
    .me {
        width: 18rem; 
        height: 24rem;
        left: calc(50% - 9rem); 
    }   
}

/* Small Devices - Mobile Phones */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
        
    }
    .header__socials, .scroll__down {
        display: none;
    }
    .me {
        width: 14rem; 
        height: 19rem;
        left: calc(50% - 7rem);
        padding: 1.5rem 1rem 0 1rem;
    }
    .me img {
        object-fit: contain;
    }
}

@media only screen and (min-width: 428px) and (max-width: 432px) {
    header {
        height: 100vh;
        
    }
    .header__container {
        padding: 0 1.5rem;
    }
    .me {
        width: 20rem; 
        height: 26rem; 
        left: calc(50% - 10rem); 
    }
    .scroll__down {
        bottom: 5rem; 
    }
}

 
@keyframes swing {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-15deg);
    }
    50% {
        transform: rotate(15deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.btn-swing {
    background: var(--color-primary);
    color: var(--color-bg);
    animation: swing 2s ease-in-out infinite; 
    display: inline-block;
    transition: transform 0.5s ease; 
}


.btn-swing:hover {
    animation: none; 
    transform: rotate(0deg); 
}


.btn-swing:not(:hover) {
    animation: swing 2s ease-in-out infinite; 
}
