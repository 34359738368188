.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);

}
.portfolio__item:hover {

    border-color: var(--color-primary-variant);
    background: transparent;

}
.portfolio__item-image {
     border-radius: 0.7rem;
     overflow: hidden;
     /* height: auto; */
}

.portfolio__item h3 {

    margin: 1.6rem 0 2rem;

}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
    justify-content: center;

}



.portfolio__filters {
    text-align: center;
    margin-bottom: 2rem;
}

.portfolio__filters button {
    background-color: var(--color-bg-variant);
    color: var(--color-primary);
    border: none;
    padding: 0.8rem 1.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: var(--transition);
}

.portfolio__filters button:hover,
.portfolio__filters button.active {
    background-color: var(--color-primary);
    color: #fff;
}



 /*  MEDIA QUERIES (Medium Devices) */

 @media screen and (max-width: 1024px) {

    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;

    }
}

/*  MEDIA QUERIES (Small Devices-Mobile-Phones) */

@media screen and (max-width: 600px) {

    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 1rem; 
    }
  
    .portfolio__filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5rem;
    }

    .portfolio__filters button {
        padding: 0.6rem 1.2rem;  
        margin: 0.4rem 0.3rem;   
        font-size: 0.9rem;       
    }
}




