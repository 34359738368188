.scroll-progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  backdrop-filter: blur(20px);
}

.scroll-progress-bar-filler {
  height: 100%;
  background: rgba(14, 176, 168, 0.689);
  transition: width 0.25s ease-out, background-color 0.25s ease-out;
}
