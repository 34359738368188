    .about__container {
        display: grid;
        grid-template-columns: 35% 50%;
        gap: 15%;
        
    }
    h2 {
        margin-bottom: 4.5rem; 
    }

    .about__me {
        width: 100%;
        height: 51%;
        aspect-ratio: 1/1;
        border-radius: 2rem;
        background: linear-gradient(60deg, transparent, var(--color-primary), transparent);
        display: grid;
        place-items: center;
        
    }

    .about__me-image {
        width: 100%;
        height: 100%;
        border-radius: 2rem;
        overflow: hidden;
        transform: rotate(10deg);
        transition: var(--transition);
        filter: grayscale(100%); 

    }
    .about__me-image:hover {
        transform: rotate(0);
        filter: grayscale(0%);    
    }

    .about__cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;

    }
    .about__card {
        background: var(--color-bg-variant);
        border: 1px solid transparent;
        border-radius: 1rem;
        padding: 2rem;
        text-align: center;
        transition: var(--transition);

    }

    .about__card:hover {
        background: transparent;
        border-color: var(--color-primary-variant);
        cursor: default;
    }

    .about__icon {

        color: var(--color-primary);
        font-size: 1.4rem;
        margin-bottom:1rem;

    }
    .about__cards h5 {
        font-size: 0.95rem;
        
    }
    .about__cards small {
        font-size: 0.7rem;
        color: var(--color-light);
    }

    .about__content p {
        margin: 2rem 0 2.6rem;
        color: var(--color-light);
    }

    section#about {
        padding-top: 0;
        margin-top: 3rem; 
    }

    /*  MEDIA QUERIES (Medium Devices) */

    @media screen and (max-width: 1024px) {

        .about__container {
            grid-template-columns: 1fr;
            gap: 0;

        }
        section#about {
            margin-top: 3rem; 
        }
        .about__me {
            width: 50%;
            margin: 2rem auto 7rem;

        }
        .about__content p {
            margin: 1rem 0 1.5rem;
        }
        .about__content {
            text-align: center;
            margin-top: -6rem;

        }
    }

    /*  MEDIA QUERIES (Small Devices-Mobile-Phones) */

    @media screen and (max-width: 600px) {

        .about__me{
        margin: 0 auto 9rem;
            width: 80%; 
            /* height: auto;  */
            height: 43%;
        }
        section#about {
            margin-top: 3rem; 
        }
        .about__cards {
            grid-template-columns: 1fr;
            gap: 1rem;
        }
        .about__content {
            text-align: center;
            margin-top: -9rem;

        }
        .about__content p {
            margin: 1.5rem 0;

        }
                
    }

@media only screen and (min-width: 428px) and (max-width: 432px) {
    .about__me {
        width: 65%; 
        margin: 2.5rem auto 5rem; 
    }
    .about__cards {
        gap: 1.5rem; 
    }
    .about__content p {
        margin: 1.5rem 0 2rem; 
    }
    
}

/* @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(77, 181, 255, 0.7);
    }
    70% {
      transform: scale(1.05);
      box-shadow: 0 0 10px 10px rgba(77, 181, 255, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(77, 181, 255, 0);
    }
  }
  
  .btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
    animation: pulse 2s infinite;
  } */
  

  
  @keyframes swing {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-15deg);
    }
    50% {
        transform: rotate(15deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.btn-swing {
    background: var(--color-primary);
    color: var(--color-bg);
    animation: swing 2s ease-in-out infinite; 
    display: inline-block;
    transition: transform 0.5s ease; 
}


.btn-swing:hover {
    animation: none; 
    transform: rotate(0deg); 
}


.btn-swing:not(:hover) {
    animation: swing 2s ease-in-out infinite; 
}


  