.container.contact__container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition) ;
     
}

.contact__option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);

}

.contact__option-icon{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    font-size: 1.5rem;
    margin-bottom: 0.5rem;

}
.animated-icons {
    height: 50px;
    width: 50px;
}

.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem; 
}

/*  Styling Form Section */

form {
     display: flex;
     flex-direction: column;
     gap: 1.2rem;

}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);

}


    /*  MEDIA QUERIES (Medium Devices) */

    @media screen and (max-width: 1024px) {

        .container.contact__container {
            grid-template-columns: 1fr;
            gap: 2rem;

        }
    }

    /*  MEDIA QUERIES (Small Devices-Mobile-Phones) */

    @media screen and (max-width: 600px) {
 

        .container.contact__container {
            width:var(--container-width-sm) ;
            
        }

    }


   
    @keyframes swing {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(-15deg);
        }
        50% {
            transform: rotate(15deg);
        }
        75% {
            transform: rotate(-10deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    
    .btn-swing {
        background: var(--color-primary);
        color: var(--color-bg);
        animation: swing 2s ease-in-out infinite; 
        display: inline-block;
        transition: transform 0.5s ease; 
    }
    
    
    .btn-swing:hover {
        animation: none; 
        transform: rotate(0deg); 
    }
    
    
    .btn-swing:not(:hover) {
        animation: swing 2s ease-in-out infinite; 
    }